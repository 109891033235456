import { Box, Container, Typography } from "@mui/material";
import { columnStartStyles } from "../../../style/flex";
import Footer from "../../../components/Footer";

const LegalFr = () => {
    return (
        <Box sx={styles.main}>
            <Container sx={styles.container}>
                <Typography component={"h1"} variant="h1" mb={4}>
                    Mentions légales
                </Typography>
                <Typography>En vigueur au 23/08/2020</Typography>
                <Box sx={styles.nodivider} />

                <Typography mt={4} sx={styles.text}>
                    Conformément aux dispositions des Articles 6-III et 19 de la
                    Loi n°2004-575 du 21 juin 2004 pour la Confiance dans
                    l’économie numérique, dite L.C.E.N., il est porté à la
                    connaissance des utilisateurs et visiteurs, ci-après l’ «
                    Utilisateur » du site Internet accessible sur le lien
                    suivant : https://periplestudio.com, ci-après l’
                    "Application", les présentes mentions légales.
                </Typography>

                <Typography mt={4} sx={styles.text}>
                    La connexion et la navigation sur l’application par
                    l’Utilisateur implique acceptation intégrale et sans réserve
                    des présentes mentions légales.
                </Typography>

                <Typography mt={4} sx={styles.text}>
                    Ces dernières sont accessibles sur l’application à la
                    rubrique « Mentions légales ».
                </Typography>
                <Box sx={styles.nodivider} />

                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    ARTICLE 1 - L'EDITEUR
                </Typography>

                <Typography sx={styles.text}>
                    L'édition de l’Application est assurée par Périple Studio
                    SAS au capital de 1000 euros, immatriculée au Registre du
                    Commerce et des Sociétés de Clermont-Ferrand sous le numéro
                    894383553 dont le siège social est situé au 7 rue
                    Viollet-le-Duc,
                </Typography>
                <Typography sx={styles.text}>
                    Numéro de téléphone 0667022404,
                    <br />
                    Adresse e-mail : contact@periplestudio.com.
                    <br />
                    N° de TVA intracommunautaire : FR12894383553
                    <br />
                    Le Directeur de la publication est Victor JAFFEUX
                    <br />
                    ci-après l'"Editeur".
                </Typography>
                <Box sx={styles.nodivider} />

                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    ARTICLE 2 - L'HEBERGEUR
                </Typography>

                <Typography sx={styles.text}>
                    L'hébergeur du Site est la société IONOS SARL, dont le siège
                    social est situé au 7, place de la Gare, BP 70109, 57200
                    Sarreguemines Cedex , avec le numéro de téléphone :
                    0970808911 + adresse mail de contact : info@IONOS.fr
                </Typography>
                <Box sx={styles.nodivider} />

                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    ARTICLE 3 - ACCES À L’APPLICATION
                </Typography>

                <Typography sx={styles.text}>
                    L’Application est accessible en tout endroit, 7j/7, 24h/24
                    sauf cas de force majeure, interruption programmée ou non et
                    pouvant découlant d’une nécessité de maintenance.
                </Typography>

                <Typography sx={styles.text}>
                    En cas de modification, interruption ou suspension de
                    l’Application, l'Editeur ne saurait être tenu responsable.
                </Typography>

                <Box sx={styles.nodivider} />
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    ARTICLE 4 - COLLECTE DES DONNEES
                </Typography>

                <Typography sx={styles.text}>
                    Toute utilisation, reproduction, diffusion,
                    commercialisation, modification de toute ou partie de
                    l’Application, sans autorisation de l’Editeur est prohibée
                    et pourra entraînée des actions et poursuites judiciaires
                    telles que notamment prévues par le Code de la propriété
                    intellectuelle et le Code civil.
                </Typography>

                <Typography sx={styles.text}>
                    Pour plus d’informations, se reporter aux CGUV de
                    l’Application accessible à la rubrique "CGUV".
                </Typography>
                <Typography sx={styles.text}>
                    Pour plus d'informations en matière de protection des
                    données à caractère personnel, se reporter à la Charte en
                    matière de protection des données à caractère personnel de
                    l’Application accessible à la rubrique "Politique de
                    confidentialité"
                </Typography>
            </Container>
            <Footer />
        </Box>
    );
};

const styles = {
    main: {
        flex: 1,
        ...columnStartStyles,
    },
    container: {
        pt: { xs: 12, sm: 16 },
        ...columnStartStyles,
        flex: 1,
    },
    header: {
        textAlign: "left",
        width: "100%",
        marginTop: 4,
    },
    text: {
        textAlign: "left",
        width: "100%",
        marginTop: 2,
        color: "grey.700",
    },
    divider: {
        width: "100%",
        borderBottom: "1px solid",
        borderColor: "divider",
        pt: { xs: 4, sm: 6 },
    },
    nodivider: {
        width: "100%",
        pt: { xs: 1, sm: 2 },
    },
};

export default LegalFr;
