export const Gilroy = "Gilroy-Bold";
export const Roboto = "Roboto-Regular";
export const Poppins = "Poppins-Regular";
export const PoppinsBold = "Poppins-Bold";

export const textStyles = {
    h1: {
        fontFamily: PoppinsBold,
        fontSize: 60,
        fontWeight: 600,
        lineHeight: 78 / 70,
        letterSpacing: -0.2,
    },
    h2: {
        fontFamily: PoppinsBold,
        fontSize: 48,
        fontWeight: 600,
        lineHeight: 1.2,
    },
    h3: {
        fontFamily: PoppinsBold,
        fontSize: 42,
        lineHeight: 1.2,
    },
    h4: {
        fontFamily: PoppinsBold,
        fontSize: 36,
        fontWeight: 500,
        lineHeight: 1.5,
    },
    h5: {
        fontFamily: PoppinsBold,
        fontSize: 20,
        fontWeight: 600,
    },
    h6: {
        fontFamily: PoppinsBold,
        fontSize: 18,
    },
    subtitle1: {
        fontFamily: Gilroy,
        fontSize: 18,
    },
    subtitle2: {
        fontFamily: Gilroy,
        fontSize: 16,
    },
    body1: {
        fontFamily: Gilroy,
        fontWeight: 400,
        fontSize: 15,
    },
    body2: {
        fontFamily: Gilroy,
        fontWeight: 400,
        fontSize: 14,
    },
    caption: {
        fontFamily: Gilroy,
        fontWeight: 400,
        fontSize: 12,
    },
};
