import { Box, Typography } from "@mui/material";
import { strings } from "../../services/translation";
import { Colors } from "../../style";
import { columnStartStyles, rowCenterStyles } from "../../style/flex";
import { normalizeSize } from "../../utils/fontSizer";
import CTAButtonVariant from "../CTAButtonVariant";
import { useNavigate } from "react-router";
import CTAButton from "../CTAButton";

const logoStyle = {
    width: "30%",
    maxWidth: normalizeSize(400),
    height: "auto",
};

const Periple = () => {
    const navigate = useNavigate();

    return (
        <Box sx={styles.logoContainer}>
            <img
                src={require("../../assets/images/logo.png")}
                alt="logo periple"
                style={logoStyle}
            />
            <Typography variant="subtitle1" sx={styles.subtitle}>
                {strings.aboutUs.subtitle}
            </Typography>
            <CTAButton
                onClick={() => navigate("/services")}
                style={styles.CTABtn}>
                <Typography sx={styles.CTAText}>
                    {strings.labels.services}
                </Typography>
            </CTAButton>
        </Box>
    );
};

const styles = {
    logoContainer: {
        pt: { xs: 14, sm: 20 },
        ...columnStartStyles,
        flex: 1,
    },
    subtitle: {
        mt: 4,
        width: { xs: "95dvw" },
        maxWidth: { xs: "95dvw", sm: "50dvw" },
        textAlign: "center",
    },
    CTABtn: {
        marginTop: 6,
        padding: "10px 50px",
        ...rowCenterStyles,
    },
    CTABtn2: {
        marginTop: 6,
        padding: "15px 50px",
        ...rowCenterStyles,
        backgroundColor: Colors.ACCENT,
    },
    CTAText: { color: Colors.PRIMARY, fontSize: normalizeSize(18) },
    CTATextVariant: { color: Colors.DARK, fontSize: normalizeSize(18) },
};

export default Periple;
