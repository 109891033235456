import { Box, Container, Typography } from "@mui/material";
import { columnStartStyles } from "../../../style/flex";
import Footer from "../../../components/Footer";

const LegalEn = () => {
    return (
        <Box sx={styles.main}>
            <Container sx={styles.container}>
                <Typography component={"h1"} variant="h1" mb={4}>
                    Legal Notices
                </Typography>
                <Typography>Effective as of 8/23/2020</Typography>
                <Box sx={styles.nodivider} />
                <Typography mt={4} sx={styles.text}>
                    In accordance with the provisions of Articles 6-III and 19
                    of Law No. 2004-575 of June 21, 2004 for Confidence in the
                    digital economy, known as L.C.E.N., it is brought to the
                    attention of users and visitors, hereinafter the "User" of
                    the website accessible at the following link:
                    https://periplestudio.com, hereinafter the "Application",
                    the present legal notice.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    The connection and browsing of the application by the User
                    implies full and unreserved acceptance of the present legal
                    notice.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    The latter are available on the website.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    These are accessible on the application under the heading
                    "Legal Notice".
                </Typography>
                <Box sx={styles.nodivider} />
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    ARTICLE 1 - THE PUBLISHER
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    The publishing of the Application is provided by Périple
                    Studio SAS with a capital of 1000 euros, registered with the
                    Registre du Commerce et des Sociétés de Clermont-Ferrand
                    under the number 894383553 whose headquarters is located at
                    7 rue Viollet-le-Duc,
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Telephone number 0667022404,
                    <br />
                    E-mail address: contact@periplestudio.com.
                    <br />
                    Intracommunity VAT number: FR12894383553
                    <br />
                    The Director of the publication is Victor JAFFEUX
                    <br />
                    hereinafter the "Publisher".
                </Typography>
                <Box sx={styles.nodivider} />
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    ARTICLE 2 - THE HOST
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    The host of the Site is the company IONOS SARL, whose head
                    office is located at 7, place de la Gare, BP 70109, 57200
                    Sarreguemines Cedex , with the telephone number: 0970808911
                    + contact email address: info@IONOS.fr
                </Typography>
                <Box sx={styles.nodivider} />
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    ARTICLE 3 - ACCESS TO THE APPLICATION
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    The Application is accessible anywhere, 7 days a week, 24
                    hours a day except in cases of force majeure, scheduled or
                    and may arise from a need for maintenance.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    In the event of modification, interruption or suspension of
                    the Application, the Publisher shall not be held
                    responsible.
                </Typography>
                <Box sx={styles.nodivider} />
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    ARTICLE 4 - COLLECTION OF DATA
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Any use, reproduction, distribution, marketing, modification
                    of all or part of the Application, without the authorization
                    of the Editor is prohibited and may result in legal action
                    and prosecution as provided for in notably provided for by
                    the Intellectual Property Code and the Civil Code. For more
                    information, please refer to the Application 's Terms
                    accessible under the heading "Terms".
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    For more information on the protection of personal data,
                    please refer to the Charter on protection of personal data
                    of the Application accessible under the heading "Privacy".
                </Typography>
            </Container>
            <Footer />
        </Box>
    );
};

const styles = {
    main: {
        flex: 1,
        ...columnStartStyles,
    },
    container: {
        pt: { xs: 12, sm: 16 },
        ...columnStartStyles,
        flex: 1,
    },
    header: {
        textAlign: "left",
        width: "100%",
        marginTop: 4,
    },
    text: {
        textAlign: "left",
        width: "100%",
        marginTop: 2,
        color: "grey.700",
    },
    divider: {
        width: "100%",
        borderBottom: "1px solid",
        borderColor: "divider",
        pt: { xs: 4, sm: 6 },
    },
    nodivider: {
        width: "100%",
        pt: { xs: 1, sm: 2 },
    },
};

export default LegalEn;
