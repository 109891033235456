/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { normalizeSize } from "../../utils/fontSizer";
import { Colors } from "../../style";
import {
    columnStartStyles,
    columnStyles,
    rowStartStyles,
} from "../../style/flex";
import { Roboto } from "../../style/fonts";
//ICONS
import { ReactComponent as AppStoreIcon } from "../../assets/images/appstore.svg";
import { ReactComponent as BrowserIcon } from "../../assets/images/browser.svg";
import { strings } from "../../services/translation";

const Talesup = () => {
    return (
        <Container sx={styles.mainContainer}>
            <Typography component={"h2"} variant="h4" sx={styles.title}>
                {strings.aboutUs.talesup.title}
            </Typography>
            <Typography variant="body1" sx={styles.subtitle}>
                {strings.aboutUs.talesup.subtitle}
            </Typography>
            <Box sx={styles.mainAppContainer}>
                <Box sx={styles.appContainer}>
                    <Box sx={styles.top}>
                        <iframe
                            style={{
                                overflow: "hidden",
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20,
                                border: "0px solid white",
                            }}
                            width="100%"
                            height="300"
                            src="https://www.youtube.com/embed/9lEsylWN_BE"
                            title="Tales Up : Les Nouveaux Jeux de Rôle | trailer officiel [Français] | Périple Studio"
                            /* frameborder="0" */
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            /* referrerolicy="strict-origin-when-cross-origin" */
                            /* allowfullscreen */
                        ></iframe>
                    </Box>
                    <Box sx={styles.appInfoContainer}>
                        <Box sx={styles.appInfo}>
                            <img
                                src={require("../../assets/images/talesup.png")}
                                width={80}
                                alt="talesup icon"
                                style={{
                                    border: "#EAF2FF solid 3px",
                                    borderRadius: "20px",
                                }}
                            />
                            <Box sx={styles.appName}>
                                <Typography
                                    sx={{
                                        fontSize: normalizeSize(30),
                                        lineHeight: normalizeSize(30),
                                        marginTop: "10px",
                                        fontFamily:
                                            '"Basier Circle", sans-serif',
                                        fontWeight: "600",
                                    }}>
                                    Tales Up
                                </Typography>
                                <Typography sx={styles.text}>
                                    Aventure
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={styles.right}>
                            <Typography
                                sx={[styles.text, { textAlign: "left" }]}>
                                {strings.aboutUs.talesup.appText}
                            </Typography>
                        </Box>
                        <Box sx={styles.stores}>
                            <Link to="https://apps.apple.com/fr/app/tales-up/id1533630240">
                                <AppStoreIcon />
                            </Link>
                            <Link to="https://play.google.com/store/apps/details?id=com.talesupreader&hl=fr">
                                <img
                                    src={require("../../assets/images/google-play-badge.png")}
                                    height="40"
                                    alt="google play link"
                                />
                            </Link>
                            <Link to="https://play.talesup.io">
                                <BrowserIcon height={40} width={130} />
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

const styles = {
    mainContainer: {
        width: "100%",
        ...columnStartStyles,
        mt: { xs: 8, sm: 12 },
        mb: { xs: 2, sm: 6 },
        p: 0,
    },
    title: {
        minWidth: { xs: "95%", sm: "60%" },
        width: { xs: "95%", sm: "95%", md: "60%" },
        textAlign: { sm: "left", md: "center" },
    },
    subtitle: {
        color: Colors.DARK,
        mt: 2,
        minWidth: { xs: "95%", sm: "60%" },
        width: { xs: "95%", sm: "95%", md: "60%" },
        textAlign: { sm: "left", md: "center" },
    },
    mainAppContainer: {
        backgroundColor: Colors.PRIMARY,
        borderBottom: "solid 1px rgb(247, 248, 249)",
        borderRadius: normalizeSize(20),
        "-webkit-box-shadow": "0px 3px 6px 0px rgba(0, 0, 0, 0.19)",
        "-moz-box-shadow": "0px 3px 6px 0px rgba(0, 0, 0, 0.19)",
        boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.19)",
        //mt: { xs: 8, sm: 12 },
        minWidth: { xs: "95%", sm: "60%" },
        display: "flex",
        flexDirection: "row",
        alignItems: "space-between",
        gap: { xs: 4, sm: 8 },
        textAlign: { sm: "center", md: "left" },
        mt: { xs: 2, sm: 4 },
        mb: { xs: 2, sm: 6 },
        width: { xs: "95%", sm: "95%", md: "60%" },
        alignSelf: "center",
        overflow: "hidden",
    },
    appContainer: {
        ...columnStartStyles,
        textAlign: "left",
    },
    top: {
        width: "100%",
    },
    appInfoContainer: {
        ...columnStyles,
        px: 5,
        py: { xs: 4, sm: 6 },
        gap: 3,
    },
    appInfo: {
        ...rowStartStyles,
        columnGap: 2,
    },
    appName: {
        ...columnStyles,
    },
    text: {
        color: Colors.DARK,
        fontFamily: Roboto,
        fontSize: normalizeSize(18),
    },
    stores: {
        ...rowStartStyles,
        flexWrap: "wrap",
        gap: 2,
    },
    right: {
        textAlign: "left",
    },
};

export default Talesup;
