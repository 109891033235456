import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import { CssBaseline, PaletteMode } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Services from "./pages/Services";
import MenuAppBar from "./components/MenuAppBar";
import Home from "./pages/Home";
import getTheme from "./style/GetTheme";
import About from "./pages/About";
import LegalFr from "./pages/Legal/Fr";
import LegalEn from "./pages/Legal/En";
import Privacy from "./pages/Privacy/Fr";
import PrivacyEn from "./pages/Privacy/En";
import { getLanguage, changeLanguage, strings } from "./services/translation";
import ErrorPage from "./pages/Error";

function App() {
    const [mode, setMode] = useState<PaletteMode>("light");
    const theme = createTheme(getTheme(mode));
    const [, updateState] = useState<any>();
    const forceUpdate = useCallback(() => updateState({}), []);

    const checkLanguage = useCallback(async () => {
        const value = localStorage.getItem("language");
        if ((value === "fr" || value === "en") && value !== getLanguage()) {
            changeLanguage(value);
        } else if (!value) {
            const userLang =
                strings.getInterfaceLanguage() || navigator.language;
            if (userLang) {
                if (
                    userLang.includes("fr") ||
                    (userLang.includes("FR") && getLanguage() !== "fr")
                ) {
                    changeLanguage("fr");
                } else if (getLanguage() !== "en") {
                    changeLanguage("en");
                }
            }
        }
        forceUpdate();
    }, [forceUpdate]);

    useEffect(() => {
        checkLanguage();
    }, [checkLanguage]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
                <Route path="/" element={<Home />}>
                    <Route index element={<About />} />
                    <Route path="services" element={<Services />} />
                    <Route path="legal/fr" element={<LegalFr />} />
                    <Route path="legal/en" element={<LegalEn />} />
                    <Route path="privacy/fr" element={<Privacy />} />
                    <Route path="privacy/en" element={<PrivacyEn />} />
                    <Route path="*" element={<ErrorPage />} />
                </Route>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
