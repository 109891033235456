import { ButtonBase, Typography } from "@mui/material";
import { Colors } from "../../style";

interface CTAButtonProps {
    onClick: () => void;
    children?: any;
    style?: any;
}

const CTAButton = ({ onClick, children, style }: CTAButtonProps) => {
    return (
        <ButtonBase onClick={onClick} sx={[styles.btn, style ? style : {}]}>
            {children}
        </ButtonBase>
    );
};

const styles = {
    btn: {
        borderRadius: "999px",
        padding: "10px 30px",
        //backgroundColor: Colors.DARK,
        border: `solid 2px ${Colors.VISTA}`,
        backgroundColor: Colors.VISTA,
        borderColor: Colors.VISTA,
        transition: "background-color .3s",
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&:hover > p": {
            color: Colors.ACCENT,
            transition: "color .3s",
        },
    },
};

export default CTAButton;
