/* eslint-disable import/no-anonymous-default-export */
const french = {
    labels: {
        company: "Périple Studio",
        aboutUs: "À propos",
        services: "Nos services",
        legal: "Légal",
        terms: "Mentions Légales",
        privacy: "Confidentialité",
        contact: "Contact",
        copyright: "Copyright © ",
        talesup: "Tales Up",
    },
    actions: {
        bookAppointment: "Prendre rendez-vous",
        contactUs: "Nous contacter",
        backToMenu: "Retour",
    },
    urls: {
        legal: "/legal/fr",
        privacy: "/privacy/fr",
    },
    aboutUs: {
        subtitle:
            "Périple est un studio indépendant de développement de jeux et d'applications mobiles. Nous avons pour ambition de créer des projets innovants à fort impact social qui ont du sens à nos yeux. Nous proposons aussi une expertise sur différents services",
        team: {
            title: "Notre équipe",
            subtitle:
                "Notre équipe donne son maximum, que ce soit pour le développement de nos jeux ou pour vos projets.",
            jaff: {
                name: "Victor Jaffeux",
                title: "Co-fondateur | Directeur créatif",
            },
            adrien: {
                name: "Adrien Lambel",
                title: "Co-fondateur | Développeur",
            },
            emilien: {
                name: "Emilien Massemin",
                title: "Narrative designer",
            },
        },
        talesup: {
            title: "Nos Jeux",
            subtitle:
                "Nous avons développé Tales Up, une application web et mobile d'histoires interactives et nous sommes en train de travailler sur un nouveau projet, nom de code : Ispa.",
            appText:
                "À chaque choix, sa conséquence. Dans Tales Up, vous êtes maîtres de votre destin. C'est vous qui décidez du chemin à prendre, de faire confiance ou non à cette mendiante qui prétend voir l'avenir ou encore de qui parmi vous devra se sacrifier si la situation dégénère…",
        },
    },
    services: {
        title: "Services sur mesure",
        subtitle:
            "Nous vous accompagnons sur la création de vos sites web ou applications mobiles sur mesure ainsi qu'en game design, narrative design et stratégie financière",
        highlights: {
            title: "Nos services",
            subtitle:
                "De la création de sites web et d'applications mobiles sur mesure au conseil en Game Design et en stratégie financière",
            websites: {
                title: "Sites sur mesure",
                subtitle:
                    "Vous avez besoin d'un site web pour présenter votre entreprise, votre jeu ou vos projets ? On vous accompagne de A à Z pour que votre site vous corresponde le plus possible.",
            },
            mobile: {
                title: "Applications Mobiles",
                subtitle:
                    "Vous avez un projet d'application mobile ? Nous vous accompagnons de la conception jusqu'au déploiement. Si vous avez besoin d'assistance pour le développement, nous intervenons aussi en renfort",
            },
            back: {
                title: "Custom API & Back Office",
                subtitle:
                    "Vous avez déjà un jeu, un site ou une application et vous voulez un back office pour gérer les données ? Nous réalisons votre back office, le connectons à l'API existante ou nous la créons de zéro.",
            },
            gameDesign: {
                title: "Conseil en Game Design",
                subtitle:
                    "Nous apportons notre expertise en matière de Game Design que ce soit Freemium, F2P, Mobile, PC ou Multi-joueur ainsi qu'en Producing",
            },
            narrative: {
                title: "Conseil en Narrative Design",
                subtitle:
                    "Nous vous assistons en Narrative Design sur du World Building ou des structures et systèmes pour la narration à choix (c'est un peu notre spécialité)",
            },
            financial: {
                title: "Conseil en Stratégie Financière",
                subtitle:
                    "Vous êtes un studio et vous vous interrogez sur les subventions auxquelles vous pouvez prétendre, sur la manière de constituer votre dossier, les éléments à inclure ou sur votre business plan ? Vous êtes au bon endroit.",
            },
        },
        ready: {
            title: "Parlons-en !",
            subtitle:
                "Vous avez une idée qui vous trotte dans la tête ? Prenons rendez-vous et trouvons la solution idéale à vos besoins !",
        },
    },
    errors: {
        title: "Oups !",
        subitle: "Désolé, une erreur s'est produite.",
    },
};

export default french;
