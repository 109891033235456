import { useState } from "react";
import {
    AppBar,
    Box,
    Button,
    Container,
    Divider,
    Drawer,
    MenuItem,
    Toolbar,
    Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CTAButton from "../CTAButton";
import { Colors } from "../../style";
import { rowCenterStyles } from "../../style/flex";
import { strings } from "../../services/translation";
import { NavLink } from "react-router-dom";

const logoStyle = {
    width: "90px",
    height: "auto",
    cursor: "pointer",
};

const MenuAppBar = () => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    function mailTo() {
        window.open("mailto:contact@periplestudio.com", "_blank")?.focus();
    }

    return (
        <Box>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: 0,
                    bgcolor: Colors.PRIMARY,
                    backgroundImage: "none",
                    mt: 0.1,
                    width: "100%",
                }}>
                <Box maxWidth="none">
                    <Toolbar
                        variant="regular"
                        sx={(theme) => ({
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexShrink: 0,
                            //borderRadius: "999px",
                            bgcolor: "rgba(255, 255, 255, 0.9)",
                            marginTop: "-1px",
                            backdropFilter: "blur(24px)",
                            maxHeight: 40,
                            border: "1px solid",
                            borderTop: "0px",
                            borderColor: "divider",
                            boxShadow: `0 0 1px rgba(0, 0, 0, 0.05), 1px 1.5px 2px -1px rgba(0, 0, 0, 0.1), 4px 4px 12px -2.5px rgba(0, 0, 0, 0.1)`,
                        })}>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                alignItems: "center",
                                //ml: "-18px",
                                px: 0,
                            }}>
                            <img
                                src={require("../../assets/images/logo.png")}
                                style={logoStyle}
                                alt="logo of sitemark"
                            />
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                alignItems: "center",
                                //ml: "-18px",
                                px: 0,
                            }}>
                            <Box
                                sx={{
                                    display: { xs: "none", md: "flex" },
                                    ml: 3,
                                }}>
                                <MenuItem sx={{ py: "6px", px: "12px" }}>
                                    <NavLink
                                        to={"/"}
                                        style={{
                                            textDecoration: "none",
                                        }}>
                                        {({ isActive }) => {
                                            return isActive ? (
                                                <Typography
                                                    sx={{
                                                        textDecoration:
                                                            "underline",
                                                    }}
                                                    bgcolor="gray[100]"
                                                    variant="body2"
                                                    color="text.primary">
                                                    {strings.labels.aboutUs}
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    variant="body2"
                                                    color="text.primary">
                                                    {strings.labels.aboutUs}
                                                </Typography>
                                            );
                                        }}
                                    </NavLink>
                                </MenuItem>
                                <MenuItem sx={{ py: "6px", px: "12px" }}>
                                    <NavLink
                                        to={"/services"}
                                        style={{
                                            textDecoration: "none",
                                        }}>
                                        {({ isActive }) => {
                                            return isActive ? (
                                                <Typography
                                                    sx={{
                                                        textDecoration:
                                                            "underline",
                                                    }}
                                                    bgcolor="gray[100]"
                                                    variant="body2"
                                                    color="text.primary">
                                                    {strings.labels.services}
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    variant="body2"
                                                    color="text.primary">
                                                    {strings.labels.services}
                                                </Typography>
                                            );
                                        }}
                                    </NavLink>
                                </MenuItem>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: { xs: "none", md: "flex" },
                                gap: 0.5,
                                alignItems: "center",
                            }}>
                            <CTAButton onClick={() => mailTo()}>
                                <Typography sx={{ color: Colors.PRIMARY }}>
                                    {strings.actions.contactUs}
                                </Typography>
                            </CTAButton>
                        </Box>
                        <Box sx={{ display: { sm: "", md: "none" } }}>
                            <Button
                                variant="text"
                                color="primary"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                sx={{ minWidth: "30px", p: "4px" }}>
                                <MenuIcon />
                            </Button>
                            <Drawer
                                anchor="right"
                                open={open}
                                onClose={toggleDrawer(false)}>
                                <Box
                                    sx={{
                                        minWidth: "60dvw",
                                        p: 2,
                                        backgroundColor: "background.paper",
                                        flexGrow: 1,
                                    }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "end",
                                            flexGrow: 1,
                                        }}></Box>
                                    <MenuItem>
                                        <NavLink
                                            to={"/"}
                                            onClick={() => setOpen(false)}
                                            style={{
                                                textDecoration: "none",
                                            }}>
                                            {({ isActive }) => {
                                                return isActive ? (
                                                    <Typography
                                                        sx={{
                                                            textDecoration:
                                                                "underline",
                                                        }}
                                                        bgcolor="gray[100]"
                                                        variant="body2"
                                                        color="text.primary">
                                                        {strings.labels.aboutUs}
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        variant="body2"
                                                        color="text.primary">
                                                        {strings.labels.aboutUs}
                                                    </Typography>
                                                );
                                            }}
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem>
                                        <NavLink
                                            to={"/services"}
                                            onClick={() => setOpen(false)}
                                            style={{
                                                textDecoration: "none",
                                            }}>
                                            {({ isActive }) => {
                                                return isActive ? (
                                                    <Typography
                                                        sx={{
                                                            textDecoration:
                                                                "underline",
                                                        }}
                                                        bgcolor="gray[100]"
                                                        variant="body2"
                                                        color="text.primary">
                                                        {
                                                            strings.labels
                                                                .services
                                                        }
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        variant="body2"
                                                        color="text.primary">
                                                        {
                                                            strings.labels
                                                                .services
                                                        }
                                                    </Typography>
                                                );
                                            }}
                                        </NavLink>
                                    </MenuItem>
                                    <Divider />
                                    <Box
                                        sx={{
                                            width: "100%",
                                            ...rowCenterStyles,
                                        }}>
                                        <CTAButton
                                            onClick={() => mailTo()}
                                            style={{
                                                marginTop: 2,
                                                alignSelf: "center",
                                                width: { xs: "90%", sm: "80%" },
                                            }}>
                                            <Typography
                                                sx={{ color: Colors.PRIMARY }}>
                                                {strings.actions.contactUs}
                                            </Typography>
                                        </CTAButton>
                                    </Box>
                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
        </Box>
    );
};

export default MenuAppBar;
