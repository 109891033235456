function normalize(size: number) {
    return size;
}

export const normalizeSize = (size: number): string => {
    return size + "px";
};

export const normalizeVh = (size: number): string => {
    return size + "vh";
};

export default normalize;
