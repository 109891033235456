import { useNavigate } from "react-router-dom";
import { columnStartStyles, rowCenterStyles } from "../../style/flex";
import { Box, Container, Typography } from "@mui/material";
import { strings } from "../../services/translation";
import Footer from "../../components/Footer";
import CTAButton from "../../components/CTAButton";
import { Colors } from "../../style";
import { normalizeSize } from "../../utils/fontSizer";

const ErrorPage = () => {
    //const error: any = useRouteError();
    const navigate = useNavigate();

    return (
        <Box sx={styles.main}>
            <Container sx={styles.container}>
                <Typography
                    component={"h1"}
                    variant="h1"
                    mb={4}
                    sx={styles.header}>
                    {strings.errors.title}
                </Typography>
                <Typography sx={styles.text}>
                    {strings.errors.subitle}
                </Typography>
                <CTAButton onClick={() => navigate("/")} style={styles.CTABtn}>
                    <Typography sx={styles.CTAText}>
                        {strings.actions.backToMenu}
                    </Typography>
                </CTAButton>

                {/*  <Typography sx={styles.text}>
                    <i>{error.statusText || error.message}</i>
                </Typography> */}
            </Container>
            <Footer />
        </Box>
    );
};

const styles = {
    main: {
        flex: 1,
        ...columnStartStyles,
    },
    container: {
        pt: { xs: 12, sm: 16 },
        pb: { xs: 8, sm: 12 },
        ...columnStartStyles,
        flex: 1,
    },
    header: {
        textAlign: "center",
        width: "100%",
        marginTop: 4,
    },
    text: {
        textAlign: "center",
        width: "100%",
        marginTop: 2,
        color: "grey.700",
    },
    CTABtn: {
        marginTop: 4,
        padding: "15px 50px",
        ...rowCenterStyles,
        backgroundColor: Colors.VISTA,
        borderColor: Colors.VISTA,
    },
    CTAText: { color: Colors.PRIMARY, fontSize: normalizeSize(18) },
};

export default ErrorPage;
