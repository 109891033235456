export const PRIMARY = "#ffffff";
export const SECONDARY = "#000000";
export const ACCENT = "#7970FF";
export const LIGHT = "#F0F7FF";
export const CREME = "#fbf6ed";
export const CREME_LIGHT = "#fdfbf8";
export const MAIN = "#0959AA";
export const DARK = "#25262F";
export const CONTRAST_TEXT = "#FBFCFE";
export const OTHER_TEXT = "#00204C";

export const VISTA = "#9BA2FF";
export const CORAL = "#EF767A";

// #5CAB7D mint
export const brand = {
    50: "#F9F9F9",
    100: "#F0F0F0",
    200: "#E0E0E0",
    300: "#D0D0D0",
    400: "#B0B0B0",
    500: "#909090",
    600: "#707070",
    700: "#505050",
    800: "#303030",
    900: "#101010",
};

export const secondary = {
    50: "#F9F0FF",
    100: "#E9CEFD",
    200: "#D49CFC",
    300: "#B355F6",
    400: "#750AC2",
    500: "#6709AA",
    600: "#490679",
    700: "#3B0363",
    800: "#2F024F",
    900: "#23023B",
};

export const gray = {
    50: "#FBFCFE",
    100: "#EAF0F5",
    200: "#D6E2EB",
    300: "#BFCCD9",
    400: "#94A6B8",
    500: "#5B6B7C",
    600: "#4C5967",
    700: "#364049",
    800: "#131B20",
    900: "#090E10",
};
