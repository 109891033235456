import { Box, Typography } from "@mui/material";
import { columnStyles, rowStartStyles } from "../../style/flex";
import { Colors } from "../../style";
import { Roboto } from "../../style/fonts";

interface Member {
    icon: string;
    name: string;
    title: string;
}
const TeamMember = ({ member }: { member: Member }) => {
    return member ? (
        <Box sx={styles.container}>
            {member.icon ? (
                <img src={member.icon} alt={`${member.name} icon`} width={60} />
            ) : null}
            <Box sx={styles.textContainer}>
                <Typography
                    fontWeight="medium"
                    gutterBottom
                    sx={{ color: Colors.DARK }}>
                    {member.name}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ color: "grey.400", fontFamily: Roboto }}>
                    {member.title}
                </Typography>
            </Box>
        </Box>
    ) : null;
};

const styles = {
    container: {
        ...rowStartStyles,
        gap: 2,
        flex: 1,
    },
    textContainer: {
        ...columnStyles,
        justifyContent: "center",
    },
};

export default TeamMember;
