import { Box, Container, Typography } from "@mui/material";
import { strings } from "../../services/translation";
import CTAButton from "../CTAButton";
import { Colors } from "../../style";
import { columnCenterStyles, rowCenterStyles } from "../../style/flex";
import { normalizeSize } from "../../utils/fontSizer";

const LetsTalk = () => {
    function mailTo() {
        window.open("mailto:contact@periplestudio.com", "_blank")?.focus();
    }

    return (
        <Container sx={styles.innerContainer}>
            <Typography component={"h2"} variant="h4" sx={styles.title}>
                {strings.services.ready.title}
            </Typography>
            <Typography variant="body1" sx={styles.subtitle}>
                {strings.services.ready.subtitle}
            </Typography>
            <CTAButton onClick={() => mailTo()} style={styles.CTABtn}>
                <Typography sx={styles.CTAText}>
                    {strings.actions.contactUs}
                </Typography>
            </CTAButton>
        </Container>
    );
};

const styles = {
    innerContainer: {
        ...columnCenterStyles,
        pt: { xs: 4, sm: 10 },
        pb: { xs: 8, sm: 12 },
        //py: { xs: 8, sm: 10 },
        px: { xs: 5 },
        backgroundColor: Colors.PRIMARY,
        borderBottom: "solid 1px rgb(247, 248, 249)",
        borderRadius: normalizeSize(20),
        "-webkit-box-shadow": "0px 3px 6px 0px rgba(0, 0, 0, 0.19)",
        "-moz-box-shadow": "0px 3px 6px 0px rgba(0, 0, 0, 0.19)",
        boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.19)",
        minWidth: { xs: "95%", sm: "60%" },
        textAlign: { sm: "center", md: "left" },
        mt: { xs: 8, sm: 12 },
        mb: { xs: 2, sm: 6 },
        width: { xs: "95%", sm: "95%", md: "60%" },
        alignSelf: "center",
        overflow: "hidden",
    },
    title: {
        color: Colors.DARK,
        textAlign: "center",
    },
    subtitle: {
        color: Colors.DARK,
        textAlign: "center",
        mt: 3,
    },
    CTABtn: {
        marginTop: 6,
        padding: "15px 50px",
        ...rowCenterStyles,
        backgroundColor: Colors.VISTA,
        borderColor: Colors.VISTA,
    },
    CTAText: { color: Colors.PRIMARY, fontSize: normalizeSize(18) },
};

export default LetsTalk;
