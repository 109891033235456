import LocalizedStrings from "react-localization";
import french from "./fr";
import english from "./en";

export const strings = new LocalizedStrings({
    fr: french,
    en: english,
});

export const changeLanguage = async (languageKey: any) => {
    strings.setLanguage(languageKey);
    localStorage.setItem("language", languageKey);
    return true;
};

export const getLanguage = () => {
    return strings.getLanguage();
};
