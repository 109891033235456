import { Box, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import { strings } from "../../services/translation";
import { columnStartStyles } from "../../style/flex";
import Team from "../../components/Team";
import Talesup from "../../components/Talesup";
import Periple from "../../components/Periple";

const About = () => {
    return (
        <Box
            sx={{
                flex: 1,
                ...columnStartStyles,
            }}>
            <Periple />
            <Team />
            <Talesup />
            <Footer />
        </Box>
    );
};

export default About;
