import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
import CTAButton from "../../components/CTAButton";
import { columnStartStyles } from "../../style/flex";
import Footer from "../../components/Footer";
import Highlights from "../../components/Highlights";
import { strings } from "../../services/translation";
import LetsTalk from "../../components/LetsTalk";

const Services = () => {
    function mailTo() {
        window.open("mailto:contact@periplestudio.com", "_blank")?.focus();
    }

    return (
        <Box
            sx={{
                pt: { xs: 14, sm: 20 },

                flex: 1,
                ...columnStartStyles,
            }}>
            <Box sx={[styles.container, { pb: { xs: 8, sm: 12 } }]}>
                <Typography
                    variant="h1"
                    sx={{
                        textAlign: { xs: "center", sm: "center" },
                        fontSize: { xs: "40px", sm: "60px" },
                    }}>
                    {strings.services.title}
                </Typography>
                <Typography variant="subtitle1" sx={styles.subtitle}>
                    {strings.services.subtitle}
                </Typography>
                <CTAButton onClick={() => mailTo()} style={styles.CTABtn}>
                    <Typography sx={styles.CTAText}>
                        {strings.actions.contactUs}
                    </Typography>
                </CTAButton>
            </Box>
            <Highlights />
            <LetsTalk />
            <Footer />
        </Box>
    );
};

export default Services;
