import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import InstaIcon from "@mui/icons-material/Instagram";

import {
    Box,
    Container,
    IconButton,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import { strings } from "../../services/translation";
import { normalizeSize } from "../../utils/fontSizer";

const logoStyle = {
    width: "100px",
    height: "auto",
};

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}>
            {strings.labels.copyright}
            Périple Studio&nbsp;
            {new Date().getFullYear()}
        </Typography>
    );
}

const Footer = () => {
    return (
        <Container
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: { xs: 4, sm: 8 },
                py: { xs: 8, sm: 10 },
                textAlign: { sm: "center", md: "left" },
            }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    width: "100%",
                    justifyContent: "space-between",
                }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                        minWidth: { xs: "100%", sm: "60%" },
                    }}>
                    <img
                        src={require("../../assets/images/logo.png")}
                        style={logoStyle}
                        alt="logo of sitemark"
                    />
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        marginTop: { xs: "20px", sm: 0 },
                    }}>
                    <Typography
                        variant="body2"
                        sx={styles.linkHeader}
                        fontWeight={600}>
                        {strings.labels.company}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "row", sm: "column" },
                            flexWrap: { xs: "wrap", sm: "nowrap" },
                            gap: 1,
                            columnGap: { xs: 3, sm: 1 },
                        }}>
                        <Link color="grey.400" href="/">
                            {strings.labels.aboutUs}
                        </Link>
                        <Link color="grey.400" href="/services">
                            {strings.labels.services}
                        </Link>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        marginTop: { xs: "20px", sm: 0 },
                    }}>
                    <Typography
                        variant="body2"
                        sx={styles.linkHeader}
                        fontWeight={600}>
                        {strings.labels.legal}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "row", sm: "column" },
                            flexWrap: { xs: "wrap", sm: "nowrap" },
                            gap: 1,
                            columnGap: { xs: 3, sm: 1 },
                        }}>
                        <Link color="grey.400" href={strings.urls.legal}>
                            {strings.labels.terms}
                        </Link>
                        <Link color="grey.400" href={strings.urls.privacy}>
                            {strings.labels.privacy}
                        </Link>
                        <Link
                            color="grey.400"
                            href="mailto:contact@periplestudio.com">
                            {strings.labels.contact}
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.footerRowContainer}>
                <div>
                    <Copyright />
                </div>
                <Stack
                    direction="row"
                    justifyContent="left"
                    spacing={1}
                    useFlexGap
                    sx={{
                        color: "grey.400",
                    }}>
                    <IconButton
                        color="inherit"
                        href="https://www.instagram.com/tales.up/"
                        aria-label="Instagram"
                        sx={{ alignSelf: "center" }}>
                        <InstaIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        href="https://twitter.com/tales_up"
                        aria-label="X"
                        sx={{ alignSelf: "center" }}>
                        <TwitterIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        href="https://www.linkedin.com/company/76361196/"
                        aria-label="LinkedIn"
                        sx={{ alignSelf: "center" }}>
                        <LinkedInIcon />
                    </IconButton>
                </Stack>
            </Box>
        </Container>
    );
};

const styles = {
    container: {},
    innerContainer: {},
    iconContainer: {},
    linkHeader: {
        fontSize: normalizeSize(16),
    },
    link: {},
    footerRowContainer: {
        display: "flex",
        justifyContent: "space-between",
        pt: { xs: 4, sm: 8 },
        width: "100%",
        borderTop: "1px solid",
        borderColor: "divider",
    },
};

export default Footer;
