/* eslint-disable import/no-anonymous-default-export */
const english = {
    labels: {
        company: "Périple Studio",
        aboutUs: "About us",
        services: "Our Services",
        legal: "Legal",
        terms: "Terms",
        privacy: "Privacy",
        contact: "Contact",
        copyright: "Copyright © ",
        talesup: "Tales Up",
    },
    actions: {
        bookAppointment: "Book an appointment",
        contactUs: "Contact us",
        backToMenu: "Go back",
    },
    urls: {
        legal: "/legal/en",
        privacy: "/privacy/en",
    },
    aboutUs: {
        subtitle:
            "Périple is an independent game and mobile application development studio. Our ambition is to create innovative projects with a strong social impact that make sense to us. We also offer expertise in various services",
        team: {
            title: "Our Team",
            subtitle:
                "Our team gives its all, whether for the development of our games or for your projects.",
            jaff: {
                name: "Victor Jaffeux",
                title: "Co-founder | Creative Director",
            },
            adrien: {
                name: "Adrien Lambel",
                title: "Co-founder | Developer",
            },
            emilien: {
                name: "Emilien Massemin",
                title: "Narrative designer",
            },
        },
        talesup: {
            title: "Our Games",
            subtitle:
                "We've developed Tales Up, a web and mobile application for interactive stories, and we're currently working on a new project, code-named Ispa.",
            appText:
                "Every choice has its consequence. In Tales Up, you are the masters of your own destiny. You decide which path to take, whether or not to trust that beggar woman who claims to see the future, or which of you will have to sacrifice yourselves if things get out of hand...",
        },
    },
    services: {
        title: "Tailor-made services",
        subtitle:
            "We'll work with you to create customized websites and mobile applications, as well as game design, narrative design and financial strategy.",
        highlights: {
            title: "What we provide",
            subtitle:
                "From the creation of customized websites and mobile applications to game design and financial strategy consulting",
            websites: {
                title: "Bespoke websites",
                subtitle:
                    "Do you need a website to present your company, your game or your projects? We'll work with you from A to Z to make sure your site is just right for you.",
            },
            mobile: {
                title: "Mobile Applications",
                subtitle:
                    "Do you have a mobile application project? We can support you from conception to deployment. If you need assistance with development, we can also provide back-up.",
            },
            back: {
                title: "Custom API & Back Office",
                subtitle:
                    "Do you already have a game, site or application and need a back office to manage the data? We'll build your back office, connecting it to the existing API or creating it from scratch.",
            },
            gameDesign: {
                title: "Game Design Consulting",
                subtitle:
                    "We bring our expertise in Game Design, whether Freemium, F2P, Mobile, PC or Multiplayer, as well as in Producing.",
            },
            narrative: {
                title: "Narrative Design Consulting",
                subtitle:
                    "We can help you with Narrative Design for World Building or structures and systems for storytelling (our speciality).",
            },
            financial: {
                title: "Financial Stategy Consulting",
                subtitle:
                    "You're a studio and you're wondering about the grants you're eligible for, how to put together your application, what to include or what your business plan should look like? You've come to the right place.",
            },
        },
        ready: {
            title: "Let's talk about it!",
            subtitle:
                "Do you have an idea in mind? Let's make an appointment and find the ideal solution to your needs!",
        },
    },
    errors: {
        title: "Oops!",
        subitle: "Sorry, an unexpected error has occurred.",
    },
};

export default english;
