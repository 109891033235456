import React from "react";
import { Outlet } from "react-router-dom";
import MenuAppBar from "../../components/MenuAppBar";
import { Box } from "@mui/material";
import { Colors } from "../../style";
//import Menu from "../../components/Menu";

function Home() {
    return (
        <Box
            className="app"
            sx={
                {
                    //backgroundImage: `linear-gradient(180deg, ${Colors.ACCENT}, #FFF)`,
                }
            }>
            <MenuAppBar />
            {/* <Menu /> */}

            <main className="content">
                <Outlet />
            </main>
        </Box>
    );
}

export default Home;
