import { Colors } from "../../style";
import { columnCenterStyles, rowCenterStyles } from "../../style/flex";
import { normalizeSize } from "../../utils/fontSizer";

export const styles = {
    container: {
        ...columnCenterStyles,
    },

    subtitle: {
        mt: 2,
        width: { xs: "95dvw" },
        maxWidth: { xs: "95dvw", sm: "50dvw" },
        textAlign: "center",
        fontSize: { xs: "16px", sm: "18px" },
    },
    CTABtn: {
        marginTop: 6,
        padding: "15px 50px",
        ...rowCenterStyles,
    },
    CTAText: { color: Colors.PRIMARY, fontSize: normalizeSize(18) },
};
