/* eslint-disable @typescript-eslint/no-explicit-any */
export const columnStyles: any = {
    display: "flex",
    flexDirection: "column",
};

export const columnCenterStyles: any = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
};

export const columnStartStyles: any = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
};

export const columnBetweenStyles: any = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
};

export const columnEvenlyStyles: any = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
};

export const columnEndStyles: any = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
};

export const columnCenterStartStyles: any = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
};

export const rowStyles: any = {
    display: "flex",
    flexDirection: "row",
};

export const rowCenterStyles: any = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
};

export const rowStartStyles: any = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
};

export const rowBetweenStyles: any = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
};

export const rowEvenlyStyles: any = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
};

export const rowEndStyles: any = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
};

export const rowWrapStyles: any = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
};

export const rowCenterEndStyles: any = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
};
