import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import { Box, Container, Typography, Grid, Stack, Card } from "@mui/material";
import { strings } from "../../services/translation";
import { Colors } from "../../style";
import TeamMember from "../TeamMember";

interface Member {
    icon: string;
    name: string;
    title: string;
}

const Team = () => {
    const items: Member[] = [
        {
            icon: require("../../assets/images/jaff.png"),
            name: strings.aboutUs.team.jaff.name,
            title: strings.aboutUs.team.jaff.title,
        },
        {
            icon: require("../../assets/images/adri.png"),
            name: strings.aboutUs.team.adrien.name,
            title: strings.aboutUs.team.adrien.title,
        },
        {
            icon: require("../../assets/images/emilien.png"),
            name: strings.aboutUs.team.emilien.name,
            title: strings.aboutUs.team.emilien.title,
        },
    ];

    return (
        <Box
            id="team"
            sx={{
                mt: { xs: 8, sm: 12 },
                py: { xs: 4, sm: 12 },
                color: "white",
                bgcolor: Colors.CREME,
                width: "100%",
                borderRadius: { xs: 0, sm: 0 },
            }}>
            <Container
                sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: 3, sm: 6 },
                }}>
                <Box
                    sx={{
                        width: { sm: "100%", md: "60%" },
                        textAlign: { sm: "left", md: "center" },
                    }}>
                    <Typography
                        component="h2"
                        variant="h4"
                        sx={{ color: Colors.DARK }}>
                        {strings.aboutUs.team.title}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ color: Colors.DARK, mt: 2 }}>
                        {strings.aboutUs.team.subtitle}
                    </Typography>
                </Box>
                <Grid container spacing={2.5}>
                    {items.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Stack
                                direction="column"
                                color="inherit"
                                component={Card}
                                spacing={1}
                                useFlexGap
                                sx={{
                                    p: 3,
                                    height: "100%",
                                    border: "none",
                                    backgroundColor: "transparent",
                                }}>
                                <TeamMember member={item} />
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

const styles = {};

export default Team;
