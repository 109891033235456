import { Box, Container, List, ListItem, Typography } from "@mui/material";
import { columnStartStyles, columnStyles } from "../../../style/flex";
import Footer from "../../../components/Footer";

const Privacy = () => {
    return (
        <Box sx={styles.main}>
            <Container sx={styles.container}>
                <Typography component={"h1"} variant="h1" mb={4} sx={styles.h1}>
                    Politique de Confidentialité
                </Typography>
                <Typography mt={3}>En vigueur au 23/08/2020</Typography>
                <Box sx={styles.nodivider} />
                <Box sx={styles.nodivider} />

                <Typography sx={styles.textCenter}>
                    www.periplestudio.com | SAS Périple Studio | Type de
                    site&nbsp;: vitrine
                </Typography>

                <Typography mt={4} sx={styles.text}>
                    Le but de cette politique de confidentialité est d'informer
                    les utilisateurs de notre site des données personnelles que
                    nous recueillerons ainsi que les informations suivantes, le
                    cas échéant :
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            Les données personnelles que nous recueillerons
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            L’utilisation des données recueillies
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            Qui a accès aux données recueillies
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            Les droits des utilisateurs du site
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            La politique de cookies du site
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Cette politique de confidentialité fonctionne parallèlement
                    aux conditions générales d’utilisation de notre site.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Lois applicables
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Conformément au Règlement général sur la protection des
                    données (RGPD), cette politique de confidentialité est
                    conforme aux règlements suivants.Les données à caractère
                    personnel doivent être :
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            traitées de manière licite, loyale et transparente
                            au regard de la personne concernée (licéité,
                            loyauté, transparence) ;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            collectées pour des finalités déterminées,
                            explicites et légitimes, et ne pas être traitées
                            ultérieurement d'une manière incompatible avec ces
                            finalités; le traitement ultérieur à des fins
                            archivistiques dans l'intérêt public, à des fins de
                            recherche scientifique ou historique ou à des fins
                            statistiques n'est pas considéré, conformément à
                            l'article 89, paragraphe 1, comme incompatible avec
                            les finalités initiales (limitation des finalités) ;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            adéquates, pertinentes et limitées à ce qui est
                            nécessaire au regard des finalités pour lesquelles
                            elles sont traitées (minimisation des données) ;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            exactes et, si nécessaire, tenues à jour; toutes les
                            mesures raisonnables doivent être prises pour que
                            les données à caractère personnel qui sont
                            inexactes, eu égard aux finalités pour lesquelles
                            elles sont traitées, soient effacées ou rectifiées
                            sans tarder (exactitude) ;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            conservées sous une forme permettant
                            l'identification des personnes concernées pendant
                            une durée n'excédant pas celle nécessaire au regard
                            des finalités pour lesquelles elles sont traitées;
                            les données à caractère personnel peuvent être
                            conservées pour des durées plus longues dans la
                            mesure où elles seront traitées exclusivement à des
                            fins archivistiques dans l'intérêt public, à des
                            fins de recherche scientifique ou historique ou à
                            des fins statistiques conformément à l'article 89,
                            paragraphe 1, pour autant que soient mises en œuvre
                            les mesures techniques et organisationnelles
                            appropriées requises par le règlement afin de
                            garantir les droits et libertés de la personne
                            concernée (limitation de la conservation) ;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            traitées de façon à garantir une sécurité appropriée
                            des données à caractère personnel, y compris la
                            protection contre le traitement non autorisé ou
                            illicite et contre la perte, la destruction ou les
                            dégâts d'origine accidentelle, à l'aide de mesures
                            techniques ou organisationnelles appropriées
                            (intégrité et confidentialité).
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Le traitement n'est licite que si, et dans la mesure où, au
                    moins une des conditions suivantes est remplie :
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            la personne concernée a consenti au traitement de
                            ses données à caractère personnel pour une ou
                            plusieurs finalités spécifiques ;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            le traitement est nécessaire à l'exécution d'un
                            contrat auquel la personne concernée est partie ou à
                            l'exécution de mesures précontractuelles prises à la
                            demande de celle-ci ;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            le traitement est nécessaire au respect d'une
                            obligation légale à laquelle le responsable du
                            traitement est soumis ;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            le traitement est nécessaire à la sauvegarde des
                            intérêts vitaux de la personne concernée ou d'une
                            autre personne physique ;
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            le traitement est nécessaire à l'exécution d'une
                            mission d'intérêt public ou relevant de l'exercice
                            de l'autorité publique dont est investi le
                            responsable du traitement ;
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            le traitement est nécessaire aux fins des intérêts
                            légitimes poursuivis par le responsable du
                            traitement ou par un tiers, à moins que ne prévalent
                            les intérêts ou les libertés et droits fondamentaux
                            de la personne concernée qui exigent une protection
                            des données à caractère personnel, notamment lorsque
                            la personne concernée est un enfant.
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Pour les résidents de l’État de Californie, cette politique
                    de confidentialité vise à se conformer à la California
                    Consumer Privacy Act (CCPA). S’il y a des incohérences entre
                    ce document et la CCPA, la législation de l’État
                    s’appliquera. Si nous constatons des incohérences, nous
                    modifierons notre politique pour nous conformer à la loi
                    pertinente.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Consentement
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Les utilisateurs conviennent qu’en utilisant notre site, ils
                    consentent à :
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            les conditions énoncées dans la présente politique
                            de confidentialité et
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            la collecte, l’utilisation et la conservation des
                            données énumérées dans la présente politique.
                        </Typography>
                    </li>
                </Box>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Données personnelles que nous collectons
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Données collectées automatiquement
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Lorsque vous visitez et utilisez notre site, nous pouvons
                    automatiquement recueillir et conserver les renseignements
                    suivants :
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            Détails matériels et logiciels
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Données recueillies de façon non automatique
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Nous pouvons également collecter les données suivantes
                    lorsque vous effectuez certaines fonctions sur notre
                    site&nbsp;:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>Prénom et nom</Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            Email
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            Numéro de téléphone
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Ces données peuvent être recueillies au moyen des méthodes
                    suivantes&nbsp;:
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Demande de contact par mail
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Veuillez noter que nous ne collectons que les données qui
                    nous aident à atteindre l’objectif énoncé dans cette
                    politique de confidentialité. Nous ne recueillerons pas de
                    données supplémentaires sans vous en informer au préalable.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Comment nous utilisons les données personnelles
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Les données personnelles recueillies sur notre site seront
                    utilisées uniquement aux fins précisées dans la présente
                    politique ou indiquées sur les pages pertinentes de notre
                    site. Nous n’utiliserons pas vos données au-delà de ce que
                    nous divulguerons.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Les données que nous recueillons automatiquement sont
                    utilisées aux fins suivantes&nbsp;:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            Connaître et stocker la langue de l'utilisateur,
                            statistiques
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Les données que nous recueillons lorsque l’utilisateur
                    exécute certaines fonctions peuvent être utilisées aux fins
                    suivantes&nbsp;:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            Traitement des demandes de contact par mail
                        </Typography>
                    </li>
                </Box>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Avec qui nous partageons les données personnelles
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    EmployésNous pouvons divulguer à tout membre de notre
                    organisation les données utilisateur dont il a
                    raisonnablement besoin pour réaliser les objectifs énoncés
                    dans la présente politique.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Tierces partiesNous pouvons partager les données utilisateur
                    avec les tiers suivants&nbsp;:
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Nous pouvons partager les données utilisateur avec des tiers
                    aux fins suivantes&nbsp;:
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Les tiers ne seront pas en mesure d’accéder aux données des
                    utilisateurs au-delà de ce qui est raisonnablement
                    nécessaire pour atteindre l’objectif donné.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Autres divulgationsNous nous engageons à ne pas vendre ou
                    partager vos données avec des tiers, sauf dans les cas
                    suivants&nbsp;:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            si la loi l'exige
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            si elle est requise pour toute procédure judiciaire
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            pour prouver ou protéger nos droits légaux
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            à des acheteurs ou des acheteurs potentiels de cette
                            société dans le cas où nous cherchons à la vendre la
                            société
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Si vous suivez des hyperliens de notre site vers un autre
                    site, veuillez noter que nous ne sommes pas responsables et
                    n’avons pas de contrôle sur leurs politiques et pratiques de
                    confidentialité.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Combien de temps nous stockons les données personnelles
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Nous ne conservons pas les données des utilisateurs au-delà
                    de ce qui est nécessaire pour atteindre les fins pour
                    lesquelles elles sont recueillies.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Comment nous protégeons vos données personnelles
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Afin d’assurer la protection de votre sécurité, nous
                    utilisons le protocole de sécurité de la couche transport
                    pour transmettre des renseignements personnels dans notre
                    système.Toutes les données stockées dans notre système sont
                    bien sécurisées et ne sont accessibles qu’à nos employés.
                    Nos employés sont liés par des accords de confidentialité
                    stricts et une violation de cet accord entraînerait le
                    licenciement de l'employé.Alors que nous prenons toutes les
                    précautions raisonnables pour nous assurer que nos données
                    d’utilisateur sont sécurisées et que les utilisateurs sont
                    protégés, il reste toujours du risque de préjudice.
                    L’Internet en sa totalité peut être, parfois, peu sûr et
                    donc nous sommes incapables de garantir la sécurité des
                    données des utilisateurs au-delà de ce qui est
                    raisonnablement pratique.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Mineurs
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Le RGPD précise que les personnes de moins de 15 ans sont
                    considérées comme des mineurs aux fins de la collecte de
                    données. Les mineurs doivent avoir le consentement d’un
                    représentant légal pour que leurs données soient
                    recueillies, traitées et utilisées.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Vos droits en tant qu’utilisateur
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    En vertu du RGPD, les utilisateurs ont les droits suivants
                    en tant que personnes concernées :
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>droit d’accès</Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            droit de rectification
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            droit à l’effacement
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            droit de restreindre le traitement
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            droit à la portabilité des données
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            droit d'objection
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Vous trouverez de plus amples informations sur ces droits au
                    chapitre 3 (art 12-23) du RGPD.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Comment modifier, supprimer ou contester les données
                    recueillies
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Si vous souhaitez que vos renseignements soient supprimés ou
                    modifiés d’une façon ou d’une autre, veuillez communiquer
                    avec notre agent de protection de la vie privée ici :
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Victor Jaffeux7 rue Viollet Le Duc, 63000, Clermont-Ferrand
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    contact@periplestudio.com
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Politique sur les cookies
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Un cookie est un petit fichier, stocké sur le disque dur
                    d’un utilisateur par le site Web. Son but est de recueillir
                    des données relatives aux habitudes de navigation de
                    l’utilisateur.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Nous utilisons les types de cookies suivants sur notre
                    site&nbsp;:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            Cookies fonctionnels : Nous les utilisons pour
                            mémoriser toutes les sélections que vous faites sur
                            notre site afin qu’elles soient sauvegardées pour
                            vos prochaines visites.
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            Cookies analytiques : Cela nous permet d’améliorer
                            la conception et la fonctionnalité de notre site en
                            recueillant des données sur le contenu auquel vous
                            accédez et sur lequel vous vous accrochez en
                            utilisant notre site.
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Vous pouvez choisir d’être averti chaque fois qu’un cookie
                    est transmis. Vous pouvez également choisir de désactiver
                    les cookies entièrement dans votre navigateur Internet, mais
                    cela peut diminuer la qualité de votre expérience
                    d’utilisation.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Modifications
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Cette politique de confidentialité peut être modifiée à
                    l’occasion afin de maintenir la conformité avec la loi et de
                    tenir compte de tout changement à notre processus de
                    collecte de données. Nous recommandons à nos utilisateurs de
                    vérifier notre politique de temps à autre pour s’assurer
                    qu’ils soient informés de toute mise à jour. Au besoin, nous
                    pouvons informer les utilisateurs par courriel des
                    changements apportés à cette politique.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Contact
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Si vous avez des questions à nous poser, n’hésitez pas à
                    communiquer avec nous en utilisant ce qui
                    suit&nbsp;:contact@periplestudio.com7 rue Viollet-le-Duc,
                    63000, Clermont-Ferrand
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Date d’entrée en vigueur&nbsp;: le 13 mai 2024
                </Typography>
            </Container>
            <Footer />
        </Box>
    );
};

const styles = {
    main: {
        flex: 1,
        ...columnStartStyles,
    },
    container: {
        pt: { xs: 12, sm: 16 },
        ...columnStartStyles,
        flex: 1,
    },
    h1: {
        textAlign: "center",
        width: "100%",
    },
    header: {
        textAlign: "left",
        width: "100%",
        marginTop: 4,
    },
    text: {
        textAlign: "left",
        width: "100%",
        marginTop: 2,
        color: "grey.700",
    },
    textCenter: {
        textAlign: "center",
        width: "100%",
        marginTop: 3,
        color: "grey.700",
    },
    span: {
        textAlign: "left",
        width: "100%",
        color: "grey.700",
        listStyleType: "",
        marginTop: 1,
    },
    divider: {
        width: "100%",
        borderBottom: "1px solid",
        borderColor: "divider",
        pt: { xs: 4, sm: 6 },
    },
    nodivider: {
        width: "100%",
        pt: { xs: 1, sm: 2 },
    },
    list: { ...columnStyles, width: "100%" },
};

export default Privacy;
