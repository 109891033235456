import { Box, Container, List, ListItem, Typography } from "@mui/material";
import { columnStartStyles, columnStyles } from "../../../style/flex";
import Footer from "../../../components/Footer";

const PrivacyEn = () => {
    return (
        <Box sx={styles.main}>
            <Container sx={styles.container}>
                <Typography component={"h1"} variant="h1" mb={4} sx={styles.h1}>
                    Privacy Policy
                </Typography>
                <Typography mt={3}>Effective 08/23/2020</Typography>
                <Box sx={styles.nodivider} />
                <Box sx={styles.nodivider} />

                <Typography sx={styles.textCenter}>
                    www.periplestudio.com | SAS Périple Studio | Type of
                    site&nbsp;: showcase
                </Typography>

                <Typography mt={4} sx={styles.text}>
                    The purpose of this privacy policy is to inform users of our
                    site of the personal data we will we will collect as well as
                    the following information, where where applicable:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            The personal data we will collect
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            The use of collected data
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            Who has access to the data collected
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            Site user rights
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            Site cookie policy
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    This privacy policy operates in parallel to our site's
                    general terms and conditions of use.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Applicable laws
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    In compliance with the General Data Protection Regulation
                    (RGPD), this privacy policy complies with the following
                    regulations. complies with the following
                    regulations.Personal data personal data must be :
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            processed lawfully, fairly and transparently with
                            regard to the data subject (lawfulness, fairness,
                            transparency);
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            collected for specific purposes, and legitimate
                            purposes, and not to be further processed further
                            processed in a way incompatible with these purposes;
                            further processing for archival purposes archival
                            purposes in the public interest, for scientific or
                            recherche scientifique ou historique ou à des fins
                            statistiques n'est pas considéré, conformément à
                            l'article 89, paragraphe 1, comme incompatible avec
                            the original purposes (purpose limitation);
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            adequate, relevant and limited to what is necessary
                            for the purposes for which they are processed (data
                            minimization);
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            accurate and, where necessary, kept up to date; all
                            reasonable steps must be taken to ensure that
                            personal data which are inaccurate, having regard to
                            the purposes for which they are processed, are
                            erased or rectified without delay (accuracy) ;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            kept in a form which permits identification of data
                            subjects for no longer than is necessary for the
                            purposes for which they are processed; personal data
                            may be kept for longer periods insofar as they are
                            processed exclusively for archival purposes in the
                            public interest, for scientific or historical
                            research purposes or for statistical purposes in
                            accordance with Article 89(1), provided that the
                            appropriate technical and organizational measures
                            required by the Regulation are implemented in order
                            to guarantee the rights and freedoms of the data
                            subject (storage limitation);
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            processed in such a way as to ensure appropriate
                            security of personal data, including protection
                            against unauthorized or unlawful processing and
                            against accidental loss, destruction or damage,
                            using appropriate technical or organizational
                            measures (integrity and confidentiality).
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Processing is lawful only if, and insofar as, at least one
                    of the following conditions is met:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            the data subject has consented to the processing of
                            his/her personal data for one or more specific
                            purposes;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            processing is necessary for the performance of a
                            contract to which the data subject is party or for
                            the performance of pre-contractual measures taken at
                            the data subject's request;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            the processing is necessary to comply with a legal
                            obligation to which the data controller is subject;
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            the processing is necessary to safeguard the vital
                            interests of the data subject or of another natural
                            person;
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            the processing is necessary for the performance of a
                            task carried out in the public interest or in the
                            exercise of official authority vested in the
                            controller;
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            the processing is necessary for the purposes of the
                            legitimate interests pursued by the controller or by
                            a third party, unless the interests or fundamental
                            rights and freedoms of the data subject which
                            require the protection of personal data prevail, in
                            particular where the data subject is a child.
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    For residents of the State of California, this privacy
                    policy is intended to comply with the California Consumer
                    Privacy Act (CCPA). If there are any inconsistencies between
                    this document and the CCPA, state law will apply. If we find
                    inconsistencies, we will modify our policy to comply with
                    the relevant law.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Consent
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Users agree that by using our site, they consent to :
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            the conditions set out in this privacy policy, and
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            the collection, use and storage of the data listed
                            in this policy.
                        </Typography>
                    </li>
                </Box>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Personal data we collect
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Automatically collected data
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    When you visit and use our site, we may automatically
                    collect and store the following inform:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            Hardware and software details
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Data collected non-automatically
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    We may also collect the following data when you perform
                    certain functions on our site:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            First name and last name
                        </Typography>
                    </li>
                    <li>
                        <Typography component={"span"} sx={styles.span}>
                            Email
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>Phone number</Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    This data can be collected using the following methods:
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Request for contact by e-mail
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Please note that we only collect data that helps us achieve
                    the purpose stated in this privacy policy. We will not
                    collect any additional data without informing you first.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    How we use personal data
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Personal data collected on our site will only be used for
                    the purposes specified in this policy or indicated on the
                    relevant pages of our site. We will not use your data beyond
                    what we disclose.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    The data we automatically collect is used for the following
                    purposes:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            Know and store the user's language, statistics
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    The data we collect when the user performs certain functions
                    may be used for the following purposes:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            Processing contact requests by e-mail
                        </Typography>
                    </li>
                </Box>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    With whom we share personal data
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Employees
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    We may disclose to any member of our organization such user
                    data as is reasonably necessary to carry out the purposes
                    set out in this policy.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Third parties
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Third parties will not be able to access user data beyond
                    what is reasonably necessary to achieve the given purpose.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Other disclosures
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    We undertake not to sell or share your data with third
                    parties, except in the following cases:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            if required by law
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            si elle est requise pour toute procédure judiciaire
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            if required for any legal proceedings to prove or
                            protect our legal rights
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            to purchasers or prospective purchasers of this
                            company in the event that we seek to sell the
                            company.
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    If you follow hyperlinks from our site to another site,
                    please note that we are not responsible for and have no
                    control over their privacy policies and practices.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    How long we store personal data
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    We do not store user data beyond what is necessary to
                    achieve the purposes for which it is collected.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    How we protect your personal data
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    To protect your security, we use transport-layer security
                    protocol to transmit personal information within our system.
                    All data stored in our system is well secured and accessible
                    only to our employees. Our employees are bound by strict
                    confidentiality agreements and a breach of this agreement
                    would result in the employee's dismissal.
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    While we take every reasonable precaution to ensure that our
                    user data is secure and that users are protected, there is
                    always the risk of harm. The Internet as a whole can be, at
                    times, insecure and therefore we are unable to guarantee the
                    security of user data beyond what is reasonably practical.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Minors
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    The RGPD specifies that people under the age of 15 are
                    considered minors for the purposes of data collection.
                    Minors must have the consent of a legal representative for
                    their data to be collected, processed and used.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Your rights as a user
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Under the GDPR, users have the following rights as data
                    subjects:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            right of access
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            right of rectification
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            right to erasure
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            right to restrict processing
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            right to data portability
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            right of objection
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    Further information on these rights can be found in chapter
                    3 (art 12-23) of the RGPD.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    How to modify, delete or dispute collected data
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    If you would like your information deleted or changed in any
                    way, please contact our Privacy Officer here:
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    Victor Jaffeux7 rue Viollet Le Duc, 63000, Clermont-Ferrand,
                    France
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    contact@periplestudio.com
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Cookie policy
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    A cookie is a small file stored on a user's hard disk by a
                    website. Its purpose is to collect data relating to the
                    user's browsing habit
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    We use the following types of cookies on our site:
                </Typography>
                <Box component={"ul"} sx={[styles.list]}>
                    <li>
                        <Typography sx={styles.span}>
                            Functional cookies : We use them to memorize all the
                            selections you make on our site so that they can be
                            saved for future visits.
                        </Typography>
                    </li>
                    <li>
                        <Typography sx={styles.span}>
                            Analytical cookies This allows us to improve the
                            design and functionality of our site by collecting
                            data about the content you access and engage with
                            while using our site.
                        </Typography>
                    </li>
                </Box>
                <Typography mt={4} sx={styles.text}>
                    You can choose to be notified each time a cookie is sent.
                    You can also choose to disable cookies entirely in your
                    Internet browser, but this may reduce the quality of your
                    user experience.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Modifications
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    This Privacy Policy may be amended from time to time to
                    maintain compliance with the law and to reflect any changes
                    to our data collection process. We recommend that our users
                    check our policy from time to time to ensure that they are
                    aware of any updates. If necessary, we may notify users by
                    e-mail of changes to this policy.
                </Typography>
                <Typography component={"h2"} variant="h4" sx={styles.header}>
                    Contact
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    If you have any questions, please do not hesitate to contact
                    us using the following data:&nbsp;:
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    contact@periplestudio.com
                </Typography>
                <Typography mt={4} sx={styles.text}>
                    7 rue Viollet-le-Duc, 63000, Clermont-Ferrand
                </Typography>
            </Container>
            <Footer />
        </Box>
    );
};

const styles = {
    main: {
        flex: 1,
        ...columnStartStyles,
    },
    container: {
        pt: { xs: 12, sm: 16 },
        ...columnStartStyles,
        flex: 1,
    },
    h1: {
        textAlign: "center",
        width: "100%",
    },
    header: {
        textAlign: "left",
        width: "100%",
        marginTop: 4,
    },
    text: {
        textAlign: "left",
        width: "100%",
        marginTop: 2,
        color: "grey.700",
    },
    textCenter: {
        textAlign: "center",
        width: "100%",
        marginTop: 3,
        color: "grey.700",
    },
    span: {
        textAlign: "left",
        width: "100%",
        color: "grey.700",
        listStyleType: "",
        marginTop: 1,
    },
    divider: {
        width: "100%",
        borderBottom: "1px solid",
        borderColor: "divider",
        pt: { xs: 4, sm: 6 },
    },
    nodivider: {
        width: "100%",
        pt: { xs: 1, sm: 2 },
    },
    list: { ...columnStyles, width: "100%" },
};

export default PrivacyEn;
