import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import { Box, Container, Typography, Grid, Stack, Card } from "@mui/material";
import { strings } from "../../services/translation";
import { Colors } from "../../style";

const Highlights = () => {
    const items = [
        {
            icon: <SettingsSuggestRoundedIcon />,
            title: strings.services.highlights.websites.title,
            description: strings.services.highlights.websites.subtitle,
        },
        {
            icon: <ConstructionRoundedIcon />,
            title: strings.services.highlights.mobile.title,
            description: strings.services.highlights.mobile.subtitle,
        },
        {
            icon: <ThumbUpAltRoundedIcon />,
            title: strings.services.highlights.back.title,
            description: strings.services.highlights.back.subtitle,
        },
        {
            icon: <AutoFixHighRoundedIcon />,
            title: strings.services.highlights.gameDesign.title,
            description: strings.services.highlights.gameDesign.subtitle,
        },
        {
            icon: <SupportAgentRoundedIcon />,
            title: strings.services.highlights.narrative.title,
            description: strings.services.highlights.narrative.subtitle,
        },
        {
            icon: <QueryStatsRoundedIcon />,
            title: strings.services.highlights.financial.title,
            description: strings.services.highlights.financial.subtitle,
        },
    ];

    return (
        <Box
            id="highlights"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                color: Colors.DARK,
                bgcolor: Colors.CREME,
                //bgcolor: "#06090a",
                width: "100%",
                borderRadius: { xs: 0, sm: 0 },
            }}>
            <Container
                sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: 3, sm: 6 },
                }}>
                <Box
                    sx={{
                        width: { sm: "100%", md: "60%" },
                        textAlign: { sm: "left", md: "center" },
                    }}>
                    <Typography component="h2" variant="h4">
                        {strings.services.highlights.title}
                    </Typography>
                    <Typography variant="body1" sx={styles.subtitle}>
                        {strings.services.highlights.subtitle}
                    </Typography>
                </Box>
                <Grid container spacing={2.5}>
                    {items.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Stack
                                direction="column"
                                color="inherit"
                                component={Card}
                                spacing={1}
                                useFlexGap
                                sx={{
                                    p: 3,
                                    height: "100%",
                                    border: "none",
                                    borderColor: "grey.800",
                                    background: "transparent",
                                    backgroundColor: Colors.CREME_LIGHT,
                                    transition:
                                        "box-shadow .3s background-color .3s",
                                    "&:hover": {
                                        //borderColor: "grey.500",
                                        boxShadow:
                                            "0 0 10px 2px rgba(47, 48, 56, 0.1)",
                                    },
                                }}>
                                <Box
                                    sx={{
                                        opacity: "70%",
                                        color: Colors.CORAL,
                                    }}>
                                    {item.icon}
                                </Box>
                                <div>
                                    <Typography
                                        fontWeight="medium"
                                        sx={{ color: Colors.DARK }}
                                        gutterBottom>
                                        {item.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "grey.400" }}>
                                        {item.description}
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

const styles = {
    subtitle: {
        mt: 2,
    },
};

export default Highlights;
